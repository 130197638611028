export const bannerData = [
    {
        subHeading: 'Elevate your home with',
        heading: 'durable and stylish Fiber Doors',
        text: 'The Future of Stylish, Durable Doors.',
        ClassName: 'londonSlide',
    },
    {
        subHeading: 'Elevate your home with',
        heading: 'durable and stylish Melamine Doors',
        text: 'The Future of Stylish, Durable Doors.',
        ClassName: 'dubaiSlide',
    },
    {
        subHeading: 'Elevate your home with',
        heading: 'durable and stylish Malaysian Doors',
        text: 'The Future of Stylish, Durable Doors.',
        ClassName: 'malaysiaSlide',
    },
    {
        subHeading: 'Elevate your home with',
        heading: 'durable and stylish PVC Doors',
        text: 'The Future of Stylish, Durable Doors.',
        ClassName: 'maldivSlide',
    },
]
export const NavBannerData = [
    {
        thuHheading: 'durable and stylish Fiber Doors',
        ClassName: 'londonThumb',
        heading: 'Fiber Doors',
    },
    {
        thuHheading: 'durable and stylish Fiber Doors',
        ClassName: 'dubaiThumb',
        heading: 'Melamine Doors',
    },
    {
        thuHheading: 'durable and stylish Fiber Doors',
        ClassName: 'malaysiaThumb',
        heading: 'Malaysian Doors',
    },
    {
        thuHheading: 'durable and stylish Fiber Doors',
        ClassName: 'maldivThumb',
        heading: 'PCV Doors',
    },
]