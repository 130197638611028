import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, HeadingStyled, MainHeading, TextStyled } from '../../ui/Elements'
import { BenfitTick } from '../../ui/AllImages'

const MelamineContentModel = ({ product }) => {
    const { name, title, image } = product;
    const CustomSet = [
        {
            text: '“3 old Pakistani nail frames',
        },
        {
            text: 'full wood fillings',
        },
        {
            text: ' Prongs without nails high quality',
        },
        {
            text: 'Highly satisfactory work',
        },
    ]

    return (
        <FiberContentWrapper>
            <Container>
                <Row>
                    <Col lg={6} md={12}>
                        <Box className='d-flex flex-column align-content-center justify-content-center gap-3'>
                            <Box className='model-img'>
                                {image}
                                {/* <LazyImage src={image} /> */}
                                <HeadingStyled size='14px' spacing='0px' color='var(--themeColor)' family="var(--medium)" transform='math-auto'>{name}</HeadingStyled>
                                <HeadingStyled size='18px' spacing='0px' color='var(--themeColor)' family="var(--semiBold)">{title}</HeadingStyled>
                            </Box>
                            <Box>

                            </Box>
                            {/* <Box className='model-img-guide'>
                                <LazyImage src={GuideSize} />
                            </Box> */}
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box>
                            <MainHeading className='gradientDark my-3' size='24px' spacing='0px' family="var(--semiBold)">Type of {name}</MainHeading>
                            <TextStyled>
                                Melamine panel doors are made from a wooden core that is covered with a melamine resin coating. The resin coating is made from a type of plastic that is heat-sealed onto the wooden core. This makes the door resistant to scratches, moisture, and stains. They can come in a variety of styles and finishes to match the decor of a room.
                            </TextStyled>
                            <MainHeading className='gradientDark ' size='24px' spacing='0px' family="var(--semiBold)">The interior of the custom</MainHeading>
                            <Box className='mt-3'>
                                {
                                    CustomSet.map((data, key) => (
                                        <Box key={key} className='M-benfit d-flex align-items-baseline gap-2'>
                                            <BenfitTick />
                                            <TextStyled className='mb-1'> {data.text} </TextStyled>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </Container>

        </FiberContentWrapper>
    )
}

export default MelamineContentModel
const FiberContentWrapper = styled.div`
.M-benfit svg{
    [fill]{
        fill:var(--themeColor);
    }
    [Stroke]{
        Stroke:var(--themeColor);
    }
}
`