import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, MainHeading, TextStyled } from '../../../ui/Elements'
import { useTranslation } from 'react-i18next'
import { Video } from '../../../ui/AllImages'

const WhyChoose = () => {
    const { t } = useTranslation()
    return (
        <WhyChooseWrapper className='container-fluid common-space whyChooseFluid'>
            <Container>
                <Box className='whyChooseWrapper'>
                    <video autoPlay muted loop>
                        <source src={Video} type="video/webm" />
                    </video>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                        <MainHeading color="var(--pale)" className='py-2 whyChooseHeading'> Why Choose PolyTech </MainHeading>
                    </Box>
                    <Row className='whyChooseRow'>
                        <Col lg={6} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                                <HeadingStyled size='20px' color='var(--pale)'>CLIENT SUCCESS</HeadingStyled>
                            </Box>
                            <Box className='whyChooseText'>
                                <TextStyled color='var(--pale)'>Client satisfaction is our first priority. We respect our client and work according to the demand of our client. We make sure that each piece which we present to a client not only attract customer but also fulfill the customer need.</TextStyled>
                            </Box>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                                <HeadingStyled size='20px' color='var(--pale)'>COMMITMENT</HeadingStyled>
                            </Box>
                            <Box className='whyChooseText'>
                                <TextStyled color='var(--pale)'>We never break our commitment and never go against our commitments. We do what we say. Our each customer trust on us and we respect and care their trust. We make sure that our customer gets the best thing from us.</TextStyled>
                            </Box>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                                <HeadingStyled size='20px' color='var(--pale)'>TEAM WORK</HeadingStyled>
                            </Box>
                            <Box className='whyChooseText'>
                                <TextStyled color='var(--pale)'>We work as a team under the flag of Polytech Doors. Each or person from team works with devotion and  passion. Teamwork helps us to provide best things to each of our customers. Each design of any item comes in front of you after many checking processes.</TextStyled>
                            </Box>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <Box className='whyChooseTitle'>
                                <HeadingStyled size='20px' color='var(--pale)'>QUALITY</HeadingStyled>
                            </Box>
                            <Box className='whyChooseText'>
                                <TextStyled color='var(--pale)'>We never compromise on the quality of our each item. We always prefer to hire skilled and experienced staff. Fusion of skills and best quality materials enhance the quality or our items. Our customers admire each item which they buy from us and come back to us for more shopping.</TextStyled>
                            </Box>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </WhyChooseWrapper>

    )
}
const WhyChooseWrapper = styled.section`
    .whyChooseWrapper{
        position: relative;
        overflow: hidden;
        padding:25px ;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
            min-height: 100%;
            z-index: 1;
            filter: brightness(0.5);
        }
    }
    
`

export default WhyChoose
