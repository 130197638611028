import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { ArrowRight, BranchBg, BranchLogo, Location, Phone, Union, Whatsapp } from '../../../ui/AllImages'
import { Box, HeadingStyled, LazyImage, MainHeading } from '../../../ui/Elements'
import { ContactButton, TextWithIcon } from '../../../ui/CustomElements'

const Branch = () => {
    const BranchLocation = [
        {
            title: 'Gujranwala',
            phone: <TextWithIcon icon={Phone} title='+92 307 1000164' />,
            location: <TextWithIcon icon={Location} title='Main G.T. Road Opposite Trade Centre, Gujranwala' />,
            location2: <TextWithIcon icon={Location} title='Ali Por Chatha Road, Gondlawala Road Near Gujranwala Medical College.' />,
        },
        {
            title: 'FAISALABAD',
            phone: <TextWithIcon icon={Phone} title='+92 325 1000164' />,
            location: <TextWithIcon icon={Location} title='Main Satiana Road Khizar Masjid Mor Faisalabad' />,
            location2: <TextWithIcon icon={Location} title='Main Hajiabad Road Opposite MCB Bank' />,
        },
        {
            title: 'CHAKWAL',
            phone: <TextWithIcon icon={Phone} title='+92 314 9851768' />,
            location: <TextWithIcon icon={Location} title='Chakwal Talagang Road Near Tatta Pani Talal Plaza Balkasar' />,
            location2: <TextWithIcon icon={Location} title='Kahoot Plaza Near District Complex Opposite Lucky Aluminium Main Talagang Road, Chakwal' />,
        },

    ]
    return (
        <BranchWrapper id='branches' className='Braches' style={{ background: `url(${BranchBg})` }}>
            <Container className=''>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                        <MainHeading className='py-2 main-text main-heading gradientLight'>Our Branches</MainHeading>
                    </Box>
                </Row>
                <Row>
                    <Box className='branch-wrap'>
                        <LazyImage src={BranchLogo} />
                        <LazyImage className='union' src={Union} />
                    </Box>
                </Row>
                <Row className='branch-locations'>
                    {
                        BranchLocation.map((list, key) => (
                            <Col sm={12} md={6} lg={4}>
                                <Box key={key} className='branchWrapper'>
                                    <Box className='branchContent'>
                                        <HeadingStyled spacing='0' family='var(--bold)' className='gradientDark' >{list.title}</HeadingStyled>
                                        {list.phone}
                                        {list.location}
                                        {list.location2}
                                    </Box>
                                </Box>
                            </Col>
                        ))
                    }
                </Row>
                <Row>
                    <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                        {/* <ContactButton icon={ArrowRight} label='More' /> */}
                    </Box>
                </Row>
            </Container>
        </BranchWrapper>
    )
}

export default Branch

const BranchWrapper = styled.section`
    // background-position: center !important;
    background-size: cover !important;
     
    .branch-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    }
    .branchWrapper{
        position: relative;
        background: var(--gradientLight);
        display: flex;
        width: 100%;
        height: 190px;
        border-radius: 15px;
        padding: 12px 15px;
    }
        
    }
    .branchContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .branch-locations{
        margin-bottom:40px;
    }

`