import styled from "styled-components";

export const FooterWapper = styled.footer`
.footer-list{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    & a{
        color: var(--pale);
        opacity:0.75;
        font-family: var(--semibold);
        &:hover{
            opacity: 1;
            color: var(--pale);
        }
    }
    
}
.footer-list a{
    color: var(--pale);
    padding-bottom: 12px;
    position: relative;
    font-size:16px;
    font-family: var(--regular);
    font-weight: 400;
    // text-transform: uppercase;
    &:hover, &.active, &:focus{
        // background: var(--lightgreen);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        cursor: pointer;
        opacity: 1;
        color: var(--pale);
        font-family: var(--semibold);
        font-weight: 400;
        &::before, &.active::before{
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 15px;
            height: 3px;
            background: var(--pale);
        }
    }
}
.footer-revised{
    text-align:center;
    &>span{

        color: var(--pale);
    }
}
`