import styled from "styled-components";

export const ProductWapper = styled.section`
position:relative;

`
export const ProductTabWapper = styled.div`

.tabWrapper{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    border-top: 1px solid var(--text);
    border-bottom: 1px solid var(--text);
    justify-content: space-evenly;;
    gap: 15px;
    margin-bottom: 40px;
    margin-top: 20px;
}
.TabButtonWrapper{
    padding: 8px 20px;
    margin:15px 0;
    position: relative;
    cursor: pointer;
    span{
        display: flex;
        align-items: center;
        color: var(--text);
        font-size: 16px;
        font-family: var(--regular);
        text-align: center;
        white-space: nowrap;
        transition: all .4s ease-in-out;
        
    }
    &.active {
        background: var(--gradientDark);
        border-radius:20px;
        box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -webkit-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -moz-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
    }
    &.active span {
        background:  var(--gradientLight);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-family: var(--semibold);
    }
    &:hover{
        background: var(--gradientDark);
        border-radius:20px;
        box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -webkit-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -moz-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
    }
    &:hover span {
        background:  var(--gradientLight);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-family: var(--semibold);
    }
}
`
export const ProductCardWapper = styled.div`

#Products .slick-track .slick-slide span{
    width: 100%;
    height: 100%;
    display: flex !important;
}
#Products .slick-track .slick-slide span img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.slick-prev,  .slick-next{
    width: 40px;
    height: 40px;
    z-index:10;
}

.project-img-box{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap:20px;
    margin: 0 20px;
    padding: 15px 20px;
    border-radius: 20px;
}
.flip{
    &>span{
        transition: transform 0.6s ease-in-out !important;
        transform-style: preserve-3d;
        &>img{
            height:400px;
            width:200px;
        }
    }
}
.flip:hover >span {
    transform: rotateY(180deg);
  }
`