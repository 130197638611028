import Slider from 'react-slick'
import React, { useState } from 'react'
import { ProductCardWapper } from './Styled'
import ProductModel from '../../models/ProductModel'
import { Box, HeadingStyled, SpanStyled } from '../../../ui/Elements'
import { LeftArrow, RightArrow } from '../../../ui/AllImages'

const ProductCard = ({ menuItem }) => {
    const settings = {
        arrows: true,
        autoplay: true,
        autoplaySpeed: 1900,
        speed: 1000,
        slidesToShow: 4,
        slidesPerRow: 1,
        rows: 1,
        nextArrow: <RightArrow />,
        prevArrow: <LeftArrow />,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesPerRow: 1,
                    rows: 1,
                }
            }
        ]
    }
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };
    return (
        <ProductCardWapper id='Products'>
            <Box className=''>
                <Slider {...settings}>
                    {
                        menuItem.map((data, key) => {
                            const { name, image, title } = data;
                            return (
                                <Box key={key} onClick={() => handleProductClick(data)}>
                                    <Box className='project-img-box product-bg'>
                                        <Box className='flip'>
                                            <SpanStyled>
                                                {image}
                                            </SpanStyled>
                                        </Box>
                                        <Box className='d-flex flex-column align-items-center justify-content-center text-center'>
                                            <HeadingStyled size='14px' spacing='0px' color='var(--themeColor)' family="var(--medium)" transform='math-auto'>{name}</HeadingStyled>
                                            <HeadingStyled size='18px' spacing='0px' color='var(--themeColor)' family="var(--semiBold)">{title}</HeadingStyled>
                                        </Box>
                                    </Box>
                                </Box>
                            );
                        })
                    }
                </Slider>
            </Box>
            {selectedProduct && (
                <ProductModel
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    product={selectedProduct}
                />
            )}
        </ProductCardWapper>
    )
}

export default ProductCard;

