import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, MainHeading } from '../../ui/Elements';
import { ModelButton, } from '../../ui/CustomElements';
import FiberContentModel from './FiberContentModel';
import PvcContentModel from './PvcContentModel';
import MelamineContentModel from './MelamineContentModel';
import MalysianContentModel from './MalysianContentModel';

const ProductModel = ({ show, onClose, product }) => {
    const { name, category } = product;
    return (
        <ProductModelWrapper>
            <Modal size="xl" show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <MainHeading className='gradientDark'> {name} Details </MainHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {category === "Fiber Door" && <FiberContentModel product={product} />} */}
                    <Box className='my-4'>
                        {category === "Fiber Door" ? (
                            <FiberContentModel product={product} />
                        ) : category === "PVC Door" ? (
                            <PvcContentModel product={product} />
                        ) : category === "Malaysian Door" ? (
                            <MalysianContentModel product={product} />
                        ) : category === "Melamine Door" ? (
                            <MelamineContentModel product={product} />
                        ) : null}
                    </Box>

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" >
                        Close
                    </Button> */}
                    <ModelButton label='Close' onClick={onClose} />
                </Modal.Footer>
            </Modal>
        </ProductModelWrapper>
    )
}

export default ProductModel

const ProductModelWrapper = styled.section`
 
`