import React, { useState } from 'react'
import { Box, SpanStyled } from '../../../ui/Elements'
import { ProductTabWapper, ProductWapper } from './Styled';
import styled from 'styled-components';

const ProductsTab = ({ fillter, menuList }) => {
    const [activeTab, setActiveTab] = useState("All");

    const handleTabClick = (category) => {
        setActiveTab(category);
        fillter(category);
    };
    return (
        <ProductTabWapper>
            <Box className='tabWrapper'>
                {
                    menuList.map((data, key) => {
                        return (
                            <Box key={key} className={`TabButtonWrapper ${activeTab === data ? 'active' : ''}`} onClick={() => handleTabClick(data)} >
                                <SpanStyled >
                                    {data}
                                </SpanStyled>
                            </Box>
                        );
                    })}
            </Box>
        </ProductTabWapper>
    )
}

export default ProductsTab

