import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { Box, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { ArrowRight, BenfitTick, Ticket2, Ticket3, Ticket4, TicketImg } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import Slider from 'react-slick'

const Ticket = () => {
    const WhatsApp = () => {
        const phoneNumber = '+923071000164';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        pauseOnHover: false,
    };
    const ticketSlider = [
        {
            img: <LazyImage className='' src={TicketImg} />,
        },
        {
            img: <LazyImage className='' src={Ticket2} />,
        },
        {
            img: <LazyImage className='' src={Ticket3} />,
        },
        {
            img: <LazyImage className='' src={Ticket4} />,
        },

    ]
    const benefits = [
        {
            text: 'Leaving the doors in the sun or rain does not affect them.',
        },
        {
            text: ' The doors are not affected by termites or any kind of insect.',
        },
        {
            text: 'The durability of the doors is lifetime.',
        },
        {
            text: 'The doors can be washed with soap or soap whenever desired.',
        },
        {
            text: 'The surface of the doors is silky smooth and lasts forever.',
        },
        {
            text: 'Doors are made to order.',
        },
        {
            text: 'The door does not eat, does not grow and does not lose its color.',
        },
        {
            text: ' Doors are available in different sizes and colors.',
        },
    ]
    return (
        <TicketWapper className='container-fluid common-space'>
            <Container className='position-relative'>
                <Box className='w-100 ticketContainer'>
                    <Box className='ticket-text-card'>
                        <MainHeading className='main-heading' color='var(--pale)'>Benefits of PolyTech Doors</MainHeading>
                        {
                            benefits.map((list, key) => (
                                <Box key={key} className='d-flex align-items-baseline gap-3 '>
                                    <SpanStyled><BenfitTick /></SpanStyled>
                                    <TextStyled className='mb-0' size='16px' color='var(--pale)' family="var(--medium)" spacing='0'> {list.text}  </TextStyled>
                                </Box>

                            ))
                        }

                        <ContactButton className='my-2' icon={ArrowRight} label="Get a Quote" onClick={WhatsApp} />
                    </Box>
                    <Box className='boardingWrapper'>
                        <Slider {...settings}>
                            {
                                ticketSlider.map((list, key) => (
                                    <Box className='ticket-slider' key={key}>
                                        {list.img}
                                    </Box>
                                ))
                            }
                            {/* <LazyImage className='' src={TicketImg} /> */}
                        </Slider>
                    </Box>
                </Box>
            </Container>
        </TicketWapper>
    )
}
const TicketWapper = styled.section`
    

`
export default Ticket
