import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading, TextStyled } from '../../../ui/Elements'
import { ContactButton, OffersBox } from '../../../ui/CustomElements'
import { ArrowRight } from '../../../ui/AllImages'
import { useTranslation } from 'react-i18next'
import WhyChoose from '../whyChoose/WhyChoose'
import Products from '../products/Products'

const Offers = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+966535316584';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <OffersWrapper className='container-fluid common-space servicesFluid' id='products'>
            <Container>
                <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                    <MainHeading className='py-2 main-text main-heading gradientDark mb-2'>Our Products</MainHeading>
                    <TextStyled>"Discover our wide range of high-quality PVC doors designed to elevate your space with durability and style. From sleek panel doors to versatile sliding options, each product is crafted with precision and innovation to meet your specific needs. Explore our collection and find the perfect PVC door solution for your home or business."</TextStyled>
                </Box>
                <Row>
                    <Products />
                </Row>
                <WhyChoose />
            </Container>
        </OffersWrapper>

    )
}
const OffersWrapper = styled.section`
    
`

export default Offers
