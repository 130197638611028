import React, { useState } from 'react'
import { ProductWapper } from './Styled'
import { Container, Modal, Row } from 'react-bootstrap'
import { ProductMenu } from './Data'
import { ArrowRight, LeftArrow, RightArrow } from '../../../ui/AllImages'
import ProductsTab from './ProductsTab'
import ProductCard from './ProductCard'
import { ContactButton, ModelButton } from '../../../ui/CustomElements'
import { Box, MainHeading } from '../../../ui/Elements'
import PriceModel from '../../models/PriceModel'

export const settings = {
    arrows: true,
    // infinite: true,
    // centerPadding: "40px",
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 1500,
    slidesToShow: 4,
    slidesPerRow: 1,
    rows: 1,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 2,
                slidesPerRow: 1,
                rows: 1,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                slidesPerRow: 1,
                rows: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesPerRow: 1,
                rows: 1,
            }
        }
    ]
}
const Portfolio = () => {
    // const [activeTab, setActiveTab] = useState(1);

    // const handleTabClick = (tabNumber) => {
    //     setActiveTab(tabNumber);
    // };
    const [showModal, setShowModal] = useState(false);
    const OpenModal = () => {
        setShowModal(true);
    };

    const CloseModal = () => {
        setShowModal(false);
    };
    const uniqueData = [
        ...new Set(
            ProductMenu.map((data) => {
                return data.category;
            })
        ),
        "All",
    ];
    const [menuItem, setMenuItem] = useState(ProductMenu);
    const [menuList, setMenuList] = useState(uniqueData);

    const fillter = (category) => {
        if (category === "All") {
            setMenuItem(ProductMenu);
            return;
        }
        const updateMenu = ProductMenu.filter((data) => {
            return data.category === category;
        });
        setMenuItem(updateMenu);

    };



    return (
        <ProductWapper>
            <Container>
                <Row>
                    <ProductsTab fillter={fillter} menuList={menuList} />
                    <ProductCard menuItem={menuItem} />
                    <Box className='d-flex align-items-center justify-content-center mt-4'>
                        <ContactButton icon={ArrowRight} label='Pricing' onClick={OpenModal} />
                    </Box>
                </Row>

            </Container>
            <Modal size="xl" show={showModal} onHide={CloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <MainHeading className='gradientDark'>Claculate the Door Price </MainHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PriceModel />
                </Modal.Body>
                <Modal.Footer>
                    <ModelButton label='Close' onClick={CloseModal} />
                </Modal.Footer>
            </Modal>
        </ProductWapper>
    )
}


export default Portfolio
