import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, LazyImage } from '../Elements'
import { HeadPhone, Logo, Logo1, Whatsapp } from '../AllImages'
import { Container, Row } from 'react-bootstrap'
import { ButtonWithIcon } from '../CustomElements'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { BsJustify, BsX } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'
import { NavDataConfig } from './HeaderData'

const Header = () => {
    const navigate = useNavigate()
    const WhatsApp = () => {
        const phoneNumber = '+923071000164';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    const { t } = useTranslation();
    const [isSticky, setIsSticky] = useState(false);
    const [toggle, setToggle] = useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
        const element = document.querySelector(".nav-bar-list");
        element.classList.toggle("showToggle");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <HeaderContainer className={`${isSticky ? 'sticky' : ''} container-fluid`}>
                <Container>
                    <Row>
                        <Box className='nav-bar'>
                            <Box className='logoHeader' onClick={() => navigate('/')}>
                                {/* {isSticky ? <Logo className='logo-sticky' /> : <Logo1 />} */}
                                <Logo1 />


                            </Box>
                            <Box className='nav-bar-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to} spy={true} smooth={true} offset={-80} duration={500} activeClass="active" >{t(item.label)} </Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='d-flex align-items-center gap-2'>
                                <ButtonWithIcon className="headerWhatsapp" border="1px solid var(--white)" icon={HeadPhone} label='+923071000164' onClick={WhatsApp} />
                                <span className="toggleBtn" onClick={handleToggle}>
                                    {toggle ? <BsX size={30} /> : <BsJustify size={30} />}
                                </span>
                            </Box>
                        </Box>
                    </Row>
                    <span className="whatsapp" onClick={WhatsApp}>
                        <LazyImage src={Whatsapp} />
                    </span>
                </Container>
            </HeaderContainer>
        </>
    )
}
const HeaderContainer = styled.header`

position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    padding:30px 0;
    .nav-bar{
        display:flex;  
        align-items: center;
        justify-content: space-between;
    }
    .nav-bar-list{
        a{
            color:var(--white);
            padding-bottom: 12px;
            opacity: .75;
            cursor: pointer;
            position:relative;
            &:hover, &.active{
                color:var(--pale);
                opacity: 1;

            }
            &.active{
                font-family: var(--semiBold);
                
            }
            &:hover:after, &.active:after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right:0;
                border: 2px solid var(--pale);
                width: 70%;
                margin:auto;
            }
        }
        
    } 
    .whatsapp {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 50;
        cursor:pointer;
      }
    
    
    .logo{
        cursor:pointer;
    }
    


`

export default Header
