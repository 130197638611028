import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { BranchLogo, Client1, Client2, Client3, Client4, Client5, Client6, Client7, Client8 } from '../../../ui/AllImages'


const Expertise = () => {
    const client = [
        {
            img: <LazyImage src={Client1} />,
            // heading: 'Muhammad Arham',
            // text: 'You’re the BEST. Loved working for you and look forward to working with you again one day! Wishing you all the success in the world',
        },
        {
            img: <LazyImage src={Client2} />,
            // heading: 'Muhammad Arham',
            // text: 'You’re the BEST. Loved working for you and look forward to working with you again one day! Wishing you all the success in the world',
        },
        {
            img: <LazyImage src={Client3} />,
            // heading: 'Muhammad Arham',
            // text: 'You’re the BEST. Loved working for you and look forward to working with you again one day! Wishing you all the success in the world',
        },
        {
            img: <LazyImage src={Client4} />,
            // heading: 'Muhammad Arham',
            // text: 'You’re the BEST. Loved working for you and look forward to working with you again one day! Wishing you all the success in the world',
        },
        {
            img: <LazyImage src={Client5} />,
            // heading: 'Muhammad Arham',
            // text: 'You’re the BEST. Loved working for you and look forward to working with you again one day! Wishing you all the success in the world',
        },
        {
            img: <LazyImage src={Client6} />,
            // heading: 'Muhammad Arham',
            // text: 'You’re the BEST. Loved working for you and look forward to working with you again one day! Wishing you all the success in the world',
        },


    ]
    return (
        <ExpertiseWrapper className='container-fluid common-space expertiseFluid' id='client'>
            <Container>
                <Box className='d-flex flex-column align-items-center justify-content-center text-center main-heading'>
                    <MainHeading className='py-2 main-text main-heading gradientDark'>A Journey of Success!</MainHeading>
                    <MainHeading size="18px" className='gradientDark'>Our Accomplishments in Housing Societies!</MainHeading>
                </Box>
                <Row className='serviceRow  client-wrapper common-space'>
                    {
                        client.map((list, key) => (
                            <Col sm={12} md={6} lg={4}>
                                <Box className='img-list'>
                                    {list.img}
                                </Box>
                            </Col>
                        ))
                    }
                </Row>
            </Container>
        </ExpertiseWrapper>

    )
}
const ExpertiseWrapper = styled.section`

.client-wrapper{
    background: var(--white);
    padding: 45px 30px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
    filter: drop-shadow(0px 2px 4px #8e8e8e94);

}
.img-list{
    display: flex;
    align-items: center;
    justify-content: center;

    & img{
        width:200px;

    }
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    background: var(--pale);
    width: 100%;
    height: 230px;
    border-radius: 18px;
    text-align: center;
    margin: 12px 0 12px 0;
    transition: all .6s ease-in-out;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
  
  .flip-card:hover .flip-card-inner,
  .flip-card:focus .flip-card-inner{
    transition: all .6s ease-in-out;
    transform: rotateY(180deg);
  }
  
  .flip-card-front,
  .flip-card-back {
    transition: all .6s ease-in-out;
    position: absolute;
    width: 100%;
    height: 230px;
    border-radius: 18px;
  }
  
  .flip-card-front {
    background:var(--pale);
    color: black;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:15px;
    transition: all .6s ease-in-out;
    box-shadow: 4px 7px 5px 0px #dcdcdc94;

    .front-client-img{
        background: var(--white);
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    
        & img{
            width: 70px;
            height: 70px;
            object-fit: cover;
            border-radius: 100%;
        }
      }
  }
  
  .flip-card-back {
    background: var(--pale);
    transform: rotateY(180deg);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap:15px;
    padding: 20px 20px;
    transition: all .6s ease-in-out;
    box-shadow: 4px 7px 5px 0px #dcdcdc94;
    .back-client-img{
        background: var(--white);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    
        & img{
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 100%;
        }
      }

     & p{
        text-align: justify;
        line-height: normal;
      }

  }
  
  
    
    
`

export default Expertise
