export const NavDataConfig = [
    {
        to: 'home',
        label: 'Home',
    },
    {
        to: 'about',
        label: 'About',
    },
    {
        to: 'products',
        label: 'Products',
    },
    {
        to: 'client',
        label: 'Our Clients',
    },
    {
        to: 'branches',
        label: 'Branches',
    },
    {
        to: 'contact',
        label: 'Contact Us',
    },
]