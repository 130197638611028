import React from 'react'
import { ContactWrapper } from './styled'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, MainHeading } from '../../../ui/Elements'
import { TextWithIcon } from '../../../ui/CustomElements'
import { Email, Location, Phone } from '../../../ui/AllImages'
import ContactForm from './ContactForm'

const ContactUs = () => {
    const Map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13510.729312283036!2d74.16747451738283!3d32.15887035610675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391f2b5fe762e503%3A0xb784f81471026ea7!2sPolytech%20Fiber%20Doors!5e0!3m2!1sen!2s!4v1712181839000!5m2!1sen!2s"
    return (
        <ContactWrapper id='contact' className='common-space'>
            <Container>
                <Box className='bg-color common-space px-4'>
                    <Row>
                        <Box className='d-flex flex-column align-items-center justify-content-center text-center my-3 main-heading'>
                            <MainHeading className='py-2 main-text main-heading gradientDark'>Contact us, We are here to assist you!</MainHeading>
                        </Box>
                    </Row>
                    {/* <Row>
                        <Col>
                            <Box className='contact-box'>
                                <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Box>
                        </Col>
                    </Row> */}
                    <Row className='contact-wrapper'>
                        <Col lg={6} md={12}>
                            <Box className='contect-with-us'>
                                <ContactForm />
                            </Box>
                        </Col>
                        <Col lg={6} md={12}>

                            <Box className='contect-with-you'>
                                <Box className='d-flex flex-column gap-3 mt-5'>
                                    <Box className='contact-info'>
                                        <TextWithIcon headingSize='14px' className='contact-text' icon={Phone} title='0321-1000164 / 0554227000 / 0320-9050090' />
                                    </Box>
                                    <Box className='contact-info'>
                                        <TextWithIcon icon={Location} title='Ali Por Chatha Road, Gondlawala Road Near Gujranwala Medical College.' />
                                    </Box>
                                    <Box className='contact-info'>
                                        <TextWithIcon headingSize='14px' className='contact-text' icon={Email} title='polytechdoorspvtltd@gmail.com' />
                                    </Box>
                                    <Box className='contact-box'>
                                        <iframe src={Map} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </Box>
                                </Box>
                            </Box>
                        </Col>
                    </Row>
                </Box>
            </Container>
        </ContactWrapper >
    )
}

export default ContactUs