import React, { Component, useEffect, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { ArrowRight, SocialFacebook, SocialInstagram, SocialTwitter } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { Box, HeadingStyled, LazyImage } from '../../../ui/Elements'
import { useTranslation } from 'react-i18next'
import { NavBannerData, bannerData } from './BannerData'
import { Tiktok } from 'react-bootstrap-icons'

const AsNavFor = () => {
    const { t } = useTranslation();
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null); // Define slider1
    const [slider2, setSlider2] = useState(null); // Define slider2

    const WhatsApp = () => {
        const phoneNumber = '+923071000164';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    const SliderSetting = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: false,
        swipe: false,
    };

    const SliderSettingTh = {
        dots: false,
        infinite: true,
        nextArrow: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: false,
        pauseOnHover: false,
        swipe: false,
        // draggable: false,
        // touchMove: false,
        // draggable: false,
        // accessibility: false,

        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    className: "center",
                    centerMode: true,

                }
            }

        ]
    };
    const socialIcon = [
        {
            img: <SocialFacebook />,
            link: 'https://www.facebook.com/profile.php?id=61557710098652&mibextid=ZbWKwL',
        },


        {
            img: <Tiktok />,
            link: 'https://www.tiktok.com/@polytech.doors.pv?_t=8lM6iH3MLcf&_r=1',
        },
        {
            img: <LazyImage src={SocialInstagram} />,
            link: 'https://www.instagram.com/polytechdoorspvtltd?utm_source=qr&igsh=ZjBvejJ5bHgwNmZx',
        },
    ];

    return (
        <>
            <BannerWapper className='container-fluid banner-bg' id='home'>
                <Box className='banner-wapper-card'>
                    <Slider {...SliderSetting}
                        asNavFor={nav2}
                        ref={(slider) => (setSlider1(slider))}
                    >
                        {
                            bannerData.map((data, key) => (
                                <Box key={key} className={`container-fluid mainSlide ${data.ClassName}`}>
                                    <Container className='containerMainSlider'>
                                        <Box className='sliderContentWrapper'>
                                            <HeadingStyled className='subHeading' size='20px' color='var(--pale)'> {data.subHeading} </HeadingStyled>
                                            <Box className='sliderHeading'>
                                                {data.heading}
                                            </Box>
                                            <Box className='sliderdetail'>
                                                {data.text}
                                            </Box>
                                            <ContactButton className='sliderBtn' icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                                        </Box>
                                    </Container>
                                </Box>
                            ))
                        }
                    </Slider>
                    <Box className='thumbnailSlides'>
                        <Slider {...SliderSettingTh}
                            asNavFor={nav1}
                            ref={(slider) => (setSlider2(slider))}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                        >
                            {
                                NavBannerData.map((data, key) => (
                                    <Box key={key} className={`container-fluid mainThumb ${data.ClassName}`}>
                                        <Container>
                                            <Box className='sliderThumbWrapper'>

                                                {/* <Box className='sliderThumbDetail'>
                                                    Article 033
                                                </Box> */}
                                                <Box className='sliderThumbDetail sliderThumbHeading'>
                                                    {/* Fiber Door */}
                                                    {data.heading}
                                                </Box>
                                            </Box>
                                        </Container>
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                </Box>
                <Box className='social-link'>
                    {
                        socialIcon.map((list, key) => (
                            <Box key={key}>
                                <a href={list.link} target="_blank" rel="noopener noreferrer">
                                    {list.img}
                                </a>
                            </Box>
                        ))
                    }
                </Box>
            </BannerWapper >
        </>
    );
};

export default AsNavFor;

const BannerWapper = styled.section`
    .banner-wapper-card{
        position: relative;
        height: 100%;
    }
    .banner-card{
        padding - top:70px;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
    }
    .banner-text-card{
        display: flex;
        flex-direction: column;
        gap:20px;
        width: 56%;
    }
    
.social-link {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    display: flex;
    gap: 20px;
    z-index: 100;
    height: 170px;
    flex-wrap: wrap;
    width: 50px;
     & a{
        background-color: var(--white);
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
     }

    & svg {
        cursor: pointer;

        // &:hover {
        //     [fill] {
        //         fill: var(--lightgreen);
        //     }
        // }
    }
}

`


