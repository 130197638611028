import styled from "styled-components";

export const ContactWrapper = styled.section`
position:relative;
.bg-setting{
 border-radius:12px;
 position:relative;
}
.bg-color{
    background:var(--white);
    border-radius:20px;

}
.contact-wrapper{
    border-radius: 10px;
    position: relative;
    width:100%;
}
.contact-theme{
    position: absolute;
    height: 225px;
    top: -100px;
    bottom: 0px;
    margin: auto;
    z-index: 1;
}
.contact-right{
    right: 0;
}
.contact-left{
    left: 0;
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);
}
.contact-info .contact-svg{
    &>svg{
        [fill]{
            fill:var(--themeColor) !important;
        }
    }
}
.contact-text{
    color:var(--text);
}

.contact-links svg{
    width :24px;
    height:24px;
    cursor: pointer;
    [fill]{
        fill:var(--white);
    }

}
.contact-img{
    position:absolute;
    right:0;
    bottom:25%;
}
iframe{
    width: 100%;
    height: 400px;
    border-radius:20px;
}
    
`
export const ContactFormWrapper = styled.div`
.field-box{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin:20px 0;
}
.input-field{
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    color:var(--text);
    background: #F4F1EB;
    // box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    // -webkit-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);
    // -moz-box-shadow: 0px 0px 9px 0px rgba(152,111,31,0.2);

}

.input-field::placeholder{
    color:var(--textPalceholder);
}
.error-message{
    font-family:var(--arRegular);
    // border:1px solid red;
    color:red;
    padding:6px 8px;
    border-radius:8px;
    background-color: #ff000014;
}
.loading-btn{
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    height: 20%;
    z-index: 10;
    background: #0b0b0b6b;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color:var(--whiteColor);
    font-family:var(--regular);

}
.contact-social-links{
    position: relative;
    left: 15px;
}

`