
import AsNavFor from './banner/Banner'
import About from './about/About'
import Offers from './offers/Offers'
import Expertise from './expertise/Expertise'
import Branch from './branches/Branch'
import ContactUs from './contactUs/ContactUs'

const Index = () => {
    return (
        <>
            <AsNavFor />
            <About />
            <Offers />
            <Expertise />
            <Branch />
            <ContactUs/>


        </>
    )
}

export default Index
