import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, HeadingStyled, MainHeading, TextStyled } from '../../ui/Elements'

const PvcContentModel = ({ product }) => {
    const { name, title, image } = product;

    return (
        <FiberContentWrapper>
            <Container>
                <Row>
                    <Col lg={6} md={12}>
                        <Box className='d-flex flex-column align-content-center justify-content-center gap-3'>
                            <Box className='model-img'>
                                {image}
                                <HeadingStyled size='14px' spacing='0px' color='var(--themeColor)' family="var(--medium)" transform='math-auto'>{name}</HeadingStyled>
                                <HeadingStyled size='18px' spacing='0px' color='var(--themeColor)' family="var(--semiBold)">{title}</HeadingStyled>
                            </Box>
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box>
                            <MainHeading className='gradientDark my-3' size='24px' spacing='0px' family="var(--semiBold)">Type of {name} </MainHeading>
                            <TextStyled>PolyTech Door is one of the leading firms engaged in design manufacturer, sale and distribution of fiber doors, Qingji roof, fiber sheets, dooms and related items Established around 10 years ago, we have a strong client base and are known for strength, style, safety and affordability of our products and services. Our head office and production facilities are inLadhe Wala Warraich Gujranwala (Punjab pakistan)</TextStyled>
                        </Box>
                    </Col>
                </Row>
            </Container>

        </FiberContentWrapper>
    )
}

export default PvcContentModel
const FiberContentWrapper = styled.div`
`