import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, LabelStyled } from '../../../ui/Elements'
import { ModelButton } from '../../../ui/CustomElements'
import { ContactFormWrapper } from './styled';
import { ArrowRight } from '../../../ui/AllImages';


const ContactForm = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone_no: '',
            message: '',
        }
    })
    const getApi = async (data) => {
        try {
            setIsLoading(true);
            const response = await axios.post("https://thankful-rose-centipede.cyclic.app/api/contact-us", data);
            if (response.status === 200) {
                reset({
                    name: '',
                    email: '',
                    phone_no: '',
                    message: '',
                });
                // toast.success("Your submission was successful.", { autoClose: 3000 });
                toast.success(t('submission'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    // className: 'message'
                })
                setIsLoading(false);
            } else {
                toast.error(t('submission_fail'));
            }
        } catch (error) {
            console.error("Error submitting the form:", error);
            alert("Submission has failed.");
        }
    }
    const [phone, setPhone] = useState('');
    const handlePhoneChange = (e) => {
        // Allow only positive numbers
        const input = e.target.value;
        if (!isNaN(input) && input >= 0) {
            setPhone(input);
        }
    };


    const onSubmit = async (data) => {
        getApi(data)
        console.log(data);

    };

    return (
        <ContactFormWrapper>
            <form className='py-3' onSubmit={handleSubmit(onSubmit)}>
                <Box className='field-box'>
                    <LabelStyled color='var(--text)' >Name</LabelStyled>
                    <input className='input-field' placeholder='Enter Your Name'  {...register("name", { required: true })} aria-invalid={errors.name ? "true" : "false"} />
                    {errors.name?.type === "required" && (<p className='error-message' role="alert">Name is required</p>)}
                </Box>
                <Box className='field-box'>
                    <LabelStyled color='var(--text)' >Email</LabelStyled>
                    <input className='input-field' placeholder='Enter Your Email' type='email'{...register("email", { required: true })} aria-invalid={errors.email ? "true" : "false"} />
                    {errors.email && <p className='error-message' role="alert">Email is required</p>}
                </Box>
                <Box className='field-box'>
                    <LabelStyled color='var(--text)' >Phone</LabelStyled>
                    <input className='input-field' placeholder='Enter your Phone Number' type='text' value={phone} {...register("phone_no", { required: true })} aria-invalid={errors.phone ? "true" : "false"} onChange={handlePhoneChange} />
                    {errors.phone && <p className='error-message' role="alert">Number is requried</p>}
                </Box>
                <Box className='field-box'>
                    <LabelStyled color='var(--text)' >Your Message</LabelStyled>
                    <textarea className='input-field' placeholder='Write your message...' cols="30" rows="5" {...register("message", { required: true })} aria-invalid={errors.message ? "true" : "false"} />
                    {errors.message && <p className='error-message' role="alert">message is required</p>}
                </Box>
                <Box className='d-flex justify-content-end my-3'>
                    {isLoading ? <p className='loading-btn'><span className='spinner'></span></p> : null}
                    <ModelButton type="submit" icon={ArrowRight} label='Send' />
                    {/* <ContactButton type="submit" icon={BTNICON} label={t('Send')} /> */}
                    {/* <p className='loading-btn'>{t('Request_send')} <span className='spinner'></span></p> */}
                </Box>
            </form>
            <ToastContainer />
        </ContactFormWrapper>
    )
}

export default ContactForm