import React from 'react'
import { FooterWapper } from './Styled'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-scroll'
import { useTranslation } from 'react-i18next'
import { Box, SpanStyled } from '../Elements'
import { Facebook, Instagram, Logo, Logo1, Twitter } from '../AllImages'
import { NavDataConfig } from './HeaderData'

const Footer = () => {
    const { t, i18n } = useTranslation();
    const date_year = new Date();
    // const isRTL = i18n.dir() === 'rtl';
    const socialIcon = [
        {
            img: <Facebook />,
            link: 'https://www.facebook.com/profile.php?id=61557710098652&mibextid=ZbWKwL',
        },

        {
            img: <Twitter />,
            //   link: 'https://www.tiktok.com/@deyamlandscape',
        },
        {
            img: <Instagram />,
            link: 'https://www.instagram.com/polytechdoorspvtltd?utm_source=qr&igsh=ZjBvejJ5bHgwNmZx',
        },
    ];
    return (
        <FooterWapper className='container-fluid'>
            <Container>
                <Row>
                    <Box className='d-flex align-items-center justify-content-between gap-2 footer-content'>
                        <Box className='footerLogo'>
                            <Logo1 />
                        </Box>
                        <Box className='d-flex flex-column justify-content-center gap-2'>
                            <Box className='footer-list'>
                                {NavDataConfig.map((item, key) => (
                                    <Box key={key}>
                                        <Link to={item.to}>{t(item.label)}</Link>
                                    </Box>
                                ))}
                            </Box>
                            <Box className='footer-revised'>
                                <SpanStyled className='text-center' size='13px' color='var(--text)' transform='capitalize'>{t('AllRightReserved')} &copy; {date_year.getFullYear()} </SpanStyled>
                                <SpanStyled className='text-center' size='13px' color='var(--text)' transform='capitalize'>
                                    <SpanStyled className='fowl' color='var(--lightgreen)' size='14px' weight='bold'> Poly Tech PVT LTD</SpanStyled>
                                </SpanStyled>
                                {/* <HeadingStyled className='text-center' size='13px' color='var(--text)' transform='capitalize'>{t('Developed_by')}
                                    <SpanStyled className='fowl' color='var(--lightgreen)' size='14px' weight='bold' onClick={FowlWeb}> {t('Fowl')}</SpanStyled>
                                </HeadingStyled> */}
                            </Box>
                        </Box>
                        <Box className='d-flex align-items-center gap-3'>
                            {
                                socialIcon.map((list, key) => (
                                    <Box key={key} className='contact-links'>
                                        <a href={list.link} target="_blank" rel="noopener noreferrer">
                                            {list.img}
                                        </a>
                                    </Box>
                                ))
                            }
                        </Box>
                        {/* <FooterImg /> */}
                        {/* <Logo className='visibility' /> */}
                        {/* {isRTL ? <FooterArabicImg /> : < FooterImg />} */}
                    </Box>
                </Row>
            </Container>
        </FooterWapper>
    )
}

export default Footer