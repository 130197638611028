

import { ARTICLE01, ARTICLE011, ARTICLE013, ARTICLE014, ARTICLE020, ARTICLE023, ARTICLE024, ARTICLE025, ARTICLE026, ARTICLE027, ARTICLE028, ARTICLE029, ARTICLE030, ARTICLE031, ARTICLE032, ARTICLE033, ARTICLE035, ARTICLE038, ARTICLE039, ARTICLE040, ARTICLE050, ARTICLE051, ARTICLE053, ARTICLE055, ARTICLE056, ARTICLE057, ARTICLE059, ARTICLE060, MA1, MA2, MA3, MA4, MA5, MA6, MA7, MA8, ME1, ME2, ME3, ME4, PVC1, PVC10, PVC11, PVC12, PVC13, PVC14, PVC15, PVC16, PVC2, PVC3, PVC4, PVC5, PVC6, PVC7, PVC8, PVC9 } from "../../../ui/AllImages"
import { Img, LazyImage } from "../../../ui/Elements"


export const ProductMenu = [
    {
        id: 1,
        name: "Melamine Door",
        category: "Melamine Door",
        image: <img alt='product-img' src={ME1} />,
        title: 'Article - 01',
    },
    {
        id: 2,
        name: "Melamine Door",
        category: "Melamine Door",
        image: <img alt='product-img' src={ME2} />,
        title: 'Article - 02',
    },
    {
        id: 3,
        name: "Melamine Door",
        category: "Melamine Door",
        image: <img alt='product-img' src={ME3} />,
        title: 'Article - 03',
    },
    {
        id: 4,
        name: "Melamine Door",
        category: "Melamine Door",
        image: <img alt='product-img' src={ME4} />,
        title: 'Article - 04',
    },
    {
        id: 5,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA1} />,
        title: 'Article - 01',
    },
    {
        id: 6,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA2} />,
        title: 'Article - 02',
    },
    {
        id: 7,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA3} />,
        title: 'Article - 03',
    },
    // {
    //     id: 8,
    //     name: "Malaysian Door",
    //     category: "Malaysian Door",
    //     image: <img alt='product-img' src={MA4} />,
    //     title: 'Article - 04',
    // },
    {
        id: 9,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA5} />,
        title: 'Article - 04',
    },
    {
        id: 10,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA6} />,
        title: 'Article - 05',
    },
    {
        id: 11,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA7} />,
        title: 'Article - 06',
    },
    {
        id: 12,
        name: "Malaysian Door",
        category: "Malaysian Door",
        image: <img alt='product-img' src={MA8} />,
        title: 'Article - 07',
    },




    {
        id: 13,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE030} />,
        title: 'Article - 030',
    },
    {
        id: 14,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE050} />,
        title: 'Article - 050',
    },
    {
        id: 15,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE027} />,
        title: 'Article - 027',
    },
    {
        id: 16,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE026} />,
        title: 'Article - 026',
    },
    {
        id: 17,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE013} />,
        title: 'Article - 013',
    },
    {
        id: 18,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE035} />,
        title: 'Article - 035',
    },
    {
        id: 19,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE053} />,
        title: 'Article - 051',
    },
    {
        id: 20,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE031} />,
        title: 'Article - 031',
    },
    {
        id: 21,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE056} />,
        title: 'Article - 056',
    },
    {
        id: 22,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE023} />,
        title: 'Article - 023',
    },
    {
        id: 23,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE014} />,
        title: 'Article - 014',
    },
    // {
    //     id: 24,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     // image: <img alt='product-img' src={ARTICLE01} />,
    //     title: 'Article - 01',
    // },
    {
        id: 25,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE024} />,
        title: 'Article - 024',
    },
    {
        id: 26,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE011} />,
        title: 'Article - 011',
    },
    {
        id: 27,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE020} />,
        title: 'Article - 020',
    },
    {
        id: 28,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE039} />,
        title: 'Article - 039',
    },
    {
        id: 29,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE029} />,
        title: 'Article - 029',
    },
    {
        id: 30,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE025} />,
        title: 'Article - 025',
    },
    {
        id: 31,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE057} />,
        title: 'Article - 057',
    },
    {
        id: 32,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE028} />,
        title: 'Article - 028',
    },
    {
        id: 33,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE038} />,
        title: 'Article - 038',
    },
    {
        id: 34,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE055} />,
        title: 'Article - 055',
    },
    {
        id: 35,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE059} />,
        title: 'Article - 059',
    },
    {
        id: 36,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE040} />,
        title: 'Article - 040',
    },
    {
        id: 37,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE033} />,
        title: 'Article - 033',
    },
    // {
    //     id: 38,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src="https://res.cloudinary.com/dsqjzmey8/image/upload/v1712259822/poly-tech-products/fiber-34_ucqffq.png" />,
    //     title: 'Article - 065',
    // },
    {
        id: 39,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE032} />,
        title: 'Article - 032',
    },
    {
        id: 40,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE060} />,
        title: 'Article - 060',
    },
    {
        id: 41,
        name: "Fiber Door",
        category: "Fiber Door",
        image: <img alt='product-img' src={ARTICLE055} />,
        title: 'Article - 055',
    },
    // {
    //     id: 42,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber30} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 43,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber31} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 44,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber32} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 45,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber33} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 46,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber34} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 47,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber35} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 48,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber36} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 49,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber37} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 50,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber38} />,
    //     title: 'Article - 021',
    // },
    // {
    //     id: 51,
    //     name: "Fiber Door",
    //     category: "Fiber Door",
    //     image: <img alt='product-img' src={Fiber39} />,
    //     title: 'Article - 021',
    // },
    {
        id: 52,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC1} />,
        title: 'PVC- 1',
    },
    {
        id: 53,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC2} />,
        title: 'ARTICLE - 2',
    },
    {
        id: 54,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC3} />,
        title: 'ARTICLE - 3',
    },
    {
        id: 55,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC4} />,
        title: 'ARTICLE - 4',
    },
    {
        id: 56,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC5} />,
        title: 'ARTICLE - 5',
    },
    {
        id: 57,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC6} />,
        title: 'ARTICLE - 6',
    },
    {
        id: 58,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC7} />,
        title: 'ARTICLE - 7',
    },
    {
        id: 59,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC8} />,
        title: 'ARTICLE - 8',
    },
    {
        id: 60,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC9} />,
        title: 'ARTICLE - 9',
    },
    {
        id: 61,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC10} />,
        title: 'ARTICLE - 10',
    },
    {
        id: 62,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC11} />,
        title: 'ARTICLE - 11',
    },
    {
        id: 63,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC12} />,
        title: 'ARTICLE - 12',
    },
    {
        id: 64,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC13} />,
        title: 'ARTICLE - 13',
    },
    {
        id: 65,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC14} />,
        title: 'ARTICLE - 14',
    },
    {
        id: 66,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC15} />,
        title: 'ARTICLE - 15',
    },
    {
        id: 67,
        name: "PVC Door",
        category: "PVC Door",
        image: <img alt='product-img' src={PVC16} />,
        title: 'ARTICLE - 16',
    },

];
