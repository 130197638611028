import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, LabelStyled, SpanStyled } from '../../ui/Elements'
import {  ContactButton } from '../../ui/CustomElements'
import { ArrowRight } from '../../ui/AllImages'

const PriceModel = () => {
    const [doorType, setDoorType] = useState('regular');
    const [quantity, setQuantity] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [totalPrice, setTotalPrice] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const handleCloseErrorMessage = () => {
        setErrorMessage('');
    };

    const calculatePrice = () => {
        if (isNaN(parseFloat(length)) || isNaN(parseFloat(width)) || isNaN(parseInt(quantity))) {
            setErrorMessage('Please enter valid numeric values for length, width, and quantity.');
            return;
        }
        // Convert input values to numbers
        const lengthValue = parseFloat(length);
        const widthValue = parseFloat(width);
        const quantityValue = parseInt(quantity);

        // Convert length and width from inches to feet
        const lengthInFeet = (lengthValue / 12).toFixed(2);
        const widthInFeet = (widthValue / 12).toFixed(2);


        // Calculate area in square feet
        const areaInSquareFeet = (lengthInFeet * widthInFeet);


        // Calculate price based on door type
        let pricePerSquareFoot;
        switch (doorType) {
            case 'full':
                pricePerSquareFoot = 950;
                break;
            case 'wood':
                pricePerSquareFoot = 850;
                break;
            case 'regular':
                pricePerSquareFoot = 750;
                break;
            case 'pvc':
                pricePerSquareFoot = 280;
                break;
            case 'melamine':
                pricePerSquareFoot = 450;
                break;
            case 'malysian':
                pricePerSquareFoot = 600;
                break;
            default:
                break;
        }


        let doorValue = (areaInSquareFeet * pricePerSquareFoot).toFixed(2);
        // Calculate total price
        const totalPrice = doorValue * quantityValue;

        // Update state with the calculated result
        setTotalPrice(totalPrice);
    };

    return (
        <PriceWrapper>
            <Container>
                <Box className='pricing'>
                    <form>
                        <Row>
                            <Col lg={6} md={12}>
                                <Box className=' pricing-wrap'>
                                    <LabelStyled>Choice Door Type</LabelStyled>
                                    <select value={doorType} onChange={e => setDoorType(e.target.value)}>
                                        <option value="regular">Regular Fiber Doors</option>
                                        <option value="full">Full Fiber Doors</option>
                                        <option value="wood">Wood Fiber Doors</option>
                                        <option value="melamine"> Melamine Doors</option>
                                        <option value="malysian">Malysian Doors</option>
                                        <option value="pvc">PVC Doors</option>
                                    </select>
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className=' pricing-wrap'>
                                    <LabelStyled>Quantity </LabelStyled>
                                    <input type="number" placeholder='Select Quantity' onChange={e => setQuantity(e.target.value)} />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='pricing-wrap'>
                                    <LabelStyled>Lenght </LabelStyled>
                                    <input type="text" placeholder='Enter the Lenght' onChange={e => setLength(e.target.value)} />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='pricing-wrap'>
                                    <LabelStyled>Width </LabelStyled>
                                    <input type="text" placeholder='Enter the Width' onChange={e => setWidth(e.target.value)} />
                                </Box>
                            </Col>
                            <Col lg={6} md={12}>
                                {/* <Box className='pricing-wrap'>
                                    <LabelStyled>Total Price </LabelStyled>
                                    <SpanStyled className='price-result'>{`${totalPrice}`}</SpanStyled>
                                </Box> */}
                                <Box className='pricing-wrap'>
                                    <LabelStyled>Total Price</LabelStyled>
                                    {totalPrice !== null && (
                                        <SpanStyled className='price-result'>{`PKR = ${totalPrice}`}</SpanStyled>
                                    )}
                                </Box>

                            </Col>
                            <Col lg={6} md={12}>
                                <Box className='pricing-calculate '>
                                    <ContactButton type='Button' icon={ArrowRight} label='Calculate' onClick={calculatePrice} />
                                </Box>
                            </Col>
                            {errorMessage && (
                                <Box className='error-message'>
                                    <LabelStyled>Error</LabelStyled>
                                    <SpanStyled className='price-error'>{errorMessage}</SpanStyled>
                                    <ContactButton type='button' onClick={handleCloseErrorMessage} label='Close' />
                                </Box>
                            )}
                        </Row>
                    </form>
                </Box>
            </Container>

        </PriceWrapper>
    )
}

export default PriceModel
const PriceWrapper = styled.section`

.pricing{
    padding:15px 25px;
    background:var(--white);
    width:100%;
    border-radius:12px;
    .pricing-wrap{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin:10px 0;
        
    }
    .pricing-calculate{
        &>div{
            justify-content:end;
        }
    }
    select, input {
        width: 100%;
        padding: 8px 4px;
        border-radius: 6px;
        margin-bottom:6px;
        border:1px solid #F4F1EB;
        background: #F4F1EB;
    }
    input:focus-visible{
        border:1px solid var(--text) !important;
        outline:1px solid var(--text) !important;
    }
    input::placeholder{
        color:var(--textPalceholder);
    }
    .price-result{
        padding: 8px 12px;
        border-radius: 6px;
        background: #F4F1EB;
        border:1px solid var(--text);
    }
    .error-message{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        background: white;
        width: 500px;
        height: 220px;
        padding: 20px 45px;
        border-radius: 10px;
        align-items: center;
        justify-content: center;
        filter: drop-shadow(0px 0px 7px #a5a5a5);
        gap:25px;
        &> label{
            font-size:22px;
            color:red;
        }
        &>span{
            margin:10px 0;
            text-align:center;
            font-size:18px;
            color:var(--text);
        }
    }
}

`