import React from 'react'
import styled from 'styled-components'
import { Col, Container } from 'react-bootstrap'
import { Box, HeadingStyled, LazyImage, MainHeading, SpanStyled, TextStyled } from '../../../ui/Elements'
import { AboutImg, ArrowRight } from '../../../ui/AllImages'
import { ContactButton } from '../../../ui/CustomElements'
import { useTranslation } from 'react-i18next'
import Ticket from '../ticket/Ticket'

const About = () => {
    const { t } = useTranslation()
    const WhatsApp = () => {
        const phoneNumber = '+923071000164';
        const whatsappURL = `https://wa.me/${phoneNumber}`;
        window.open(whatsappURL, '_blank');
    };
    return (
        <AboutWapper className='container-fluid common-space aboutFluid' id='about'>
            <Container>
                <Box className='about-text-card row'>
                    <Col lg={6} md={12}>
                        <LazyImage src={AboutImg} />
                    </Col>
                    <Col lg={6} md={12}>
                        <MainHeading className='main-heading gradientDark  tStripe mb-3'>Welcome to PolyTech</MainHeading>
                        <HeadingStyled size='20px' color='var(--themeColor)' family='var(--medium)' spacing='0' transform='math-auto' >Your Premier Destination for Fiber Doors</HeadingStyled>
                        <TextStyled>
                            Polytech doors are made from a durable composite material. Composite material is a chemical compound that is chemically combined with fiberglass to form a very hard rock that does not deteriorate for decades due to weathering. All these composite material are mainly manufactured by us in our own factory.
                        </TextStyled>
                        <TextStyled>
                            Whether you're looking to enhance the aesthetics of your home or improve its security, trust PolyTech to provide the perfect PVC door solution for your needs. Explore our range of designs and experience the difference with PolyTech today.
                        </TextStyled>
                        <ContactButton icon={ArrowRight} label={t('Discover')} onClick={WhatsApp} />
                    </Col>
                </Box>
                <Ticket />
            </Container>
        </AboutWapper>
    )
}
const AboutWapper = styled.section`
    padding-bottom: 0;
    .about-text-card{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > div:first-child{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
        }
    }
`
export default About
