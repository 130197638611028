import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Box, HeadingStyled, LazyImage, MainHeading, TextStyled } from '../../ui/Elements'
import { Color, GuideSize, chogath1, chogath2 } from '../../ui/AllImages'
import { TabButton } from '../../ui/CustomElements'

const FiberContentModel = ({ product }) => {
    const { name, title, image } = product;
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    const fullFiberData = [
        {
            heading: 'WATER & WEATHER PROOF',
            text: 'PolyTech Doors 100% waterproof doors are the best solution for areas that experience heavy moisture or flooding, ensuring that your home or business is protected from water damage.',
        },
        {
            heading: 'TERMITE PROOF DOORS',
            text: 'PolyTech Doors offers the best termite-proof doors on the market, providing you with both peace of mind and beautiful design.',
        },
        {
            heading: 'DURABILITY STRENGHT',
            text: 'PolyTech Doors are built to last, thanks to the high- quality materials andrech expert craftsmanship that goes into each one.You can trust that our doors will withstand the test of time and provide you with reliable protection for years to come',
        },
        {
            heading: 'DOORFRAMES (CHOGHATS)',
            text: 'We design and fabricate fiberglass doorframes (chooghats) of any size with excellent physical properties. We also fix doors with steel-frames, which are already installed during construction. Our technicians fix doors on site at nominal charges.',
        },
        {
            heading: 'DISCOVER WIDE RANGE OF MATT POLISH COLORS!',
            text: 'Matt polish doors are a stylish and modern choice for anyone looking to add a touch of sophistication to their home or business. With a smooth, matte finish, these doors are perfect for those who prefer a more understated look.',
        },
        {
            heading: 'COLOR CHOICE',
            // text: 'Matt polish doors are a stylish and modern choice for anyone looking to add a touch of sophistication to their home or business. With a smooth, matte finish, these doors are perfect for those who prefer a more understated look.',
            color: <LazyImage src={Color} />,
        },
    ]
    return (
        <FiberContentWrapper>
            <Container>
                <Row>
                    <Col lg={6} md={12}>
                        <Box className='d-flex flex-column align-content-center justify-content-center gap-3'>
                            <Box className='model-img'>
                                {image}
                                {/* <LazyImage src={ModelImg} /> */}
                                <HeadingStyled size='14px' spacing='0px' color='var(--themeColor)' family="var(--medium)" transform='math-auto'>{name}</HeadingStyled>
                                <HeadingStyled size='18px' spacing='0px' color='var(--themeColor)' family="var(--semiBold)">{title}</HeadingStyled>
                            </Box>
                            <Box>

                            </Box>
                            <Box className='model-img-guide'>
                                <LazyImage src={chogath1} />
                                <LazyImage src={chogath2} />
                            </Box>

                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box>
                            <MainHeading className='gradientDark my-3' size='24px' spacing='0px' family="var(--semiBold)">Type of {name}</MainHeading>
                            <TextStyled>Poly Fiber Door is one of the leading firms engaged in design manufacturer, sale and distribution of fiber doors, Qingji roof, fiber sheets, dooms and related items Established around 10 years ago, we have a strong client base and are known for strength, style, safety and affordability of our products and services. Our head office and production facilities are inLadhe Wala Warraich Gujranwala (Punjab pakistan)</TextStyled>
                            <Box className="tabWrapperContainer d-flex align-items-center ">
                                <Box className="tabWrapper d-flex align-items-center justify-content-between gap-4 ">
                                    <TabButton label='Full Fiber' className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabClick(1)} />
                                    <TabButton label='Carbon Fiber' className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabClick(2)} />
                                    <TabButton label='Wood Fiber' className={activeTab === 3 ? 'active' : ''} onClick={() => handleTabClick(3)} />
                                </Box>
                            </Box>

                            {activeTab === 1 &&
                                <Box className='full-fiber-wrapper'>
                                    {
                                        fullFiberData.map((list, key) => (
                                            <Box key={key} className='full-fiber-wrapper-content'>
                                                <MainHeading className='gradientDark my-lg-2' family="var(--semiBold)" size='20px'>{list.heading}</MainHeading>
                                                <TextStyled> {list.text} </TextStyled>
                                                {list.color}
                                            </Box>
                                        ))
                                    }
                                </Box>
                            }
                            {activeTab === 2 &&
                                <Box className='full-fiber-wrapper'>
                                    {
                                        fullFiberData.map((list, key) => (
                                            <Box key={key} className='full-fiber-wrapper-content'>
                                                <MainHeading className='gradientDark my-lg-2' family="var(--semiBold)" size='20px'>{list.heading}</MainHeading>
                                                <TextStyled> {list.text} </TextStyled>
                                                {list.color}
                                            </Box>
                                        ))
                                    }
                                </Box>
                            }
                            {activeTab === 3 &&
                                <Box className='full-fiber-wrapper'>
                                    {
                                        fullFiberData.map((list, key) => (
                                            <Box key={key} className='full-fiber-wrapper-content'>
                                                <MainHeading className='gradientDark my-lg-2' family="var(--semiBold)" size='20px'>{list.heading}</MainHeading>
                                                <TextStyled> {list.text} </TextStyled>
                                                {list.color}
                                            </Box>
                                        ))
                                    }
                                </Box>
                            }
                        </Box>
                    </Col>
                </Row>
            </Container>

        </FiberContentWrapper>
    )
}

export default FiberContentModel
const FiberContentWrapper = styled.div`
`