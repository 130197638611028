import styled from 'styled-components'
import { Box, ButtonStyled, LazyImage, SpanStyled, TextStyled } from './Elements';
import { useTranslation } from 'react-i18next'

export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}

export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label', 'className'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.className || ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='18px' />
                )}
                <SpanStyled size='15px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}
export const ModelButton = (props) => {
    const unwantedProps = ['icon', 'label', 'className'];
    return (
        <ModelButtonWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.className || ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>

                <SpanStyled size='15px'>{props.label ? props.label : ''}</SpanStyled>
                {props.icon && (
                    <props.icon width='18px' />
                )}
            </button>
        </ModelButtonWrapper>
    )
}
export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label', 'className'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)} className={`d-flex align-items-center ${props.className || ''}`}>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                <SpanStyled color='var(--white)' size='16px'>{props.label ? props.label : ''}</SpanStyled>
                {props.icon && (
                    <props.icon width='18px' />
                )}
            </button>
        </ContactWrapper>
    )
}

export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute' />
            )}
        </InputStyledWrap>
    )
}

export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex align-items-start `}>
        <Box className='mx-2 d-flex align-items-center justify-content-center contact-svg'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <Box className='mb-0 contact-text' size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--text)' : 'var(--text)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </Box>
            {props.desc ?
                <TextStyled className='text-uppercase' color={'var(--text)'} size='14px' >
                    {props?.desc}
                </TextStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)


const TextWithStyledWrapper = styled.div`
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`

export const InputStyledWrap = styled.div`
    box-shadow: 3px 3px 10px #e3e3e3e0;
    -webkit-box-shadow: 3px 3px 10px #e3e3e3e0;
    -moz-box-shadow: 3px 3px 10px #e3e3e3e0;
    border-radius: 25px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`

export const InputStyled = styled.input`
    border: none;
    // box-shadow: 3px 3px 10px #e3e3e394;
    border-radius: 25px;
    height: 43px;
    color: var(--darkGrey) !important;
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 13px;
        color: #a9a9a9 !important;
    }
`

export const ContactWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background:var(--gradientLight)!important;
    color: ${props => props.color ? props.color : ""};
    padding: ${props => props.padding ? props.padding : "10px 20px"};
    border: ${props => props.border ? props.border : "none"};
    outline: ${props => props.border ? props.border : "none"};
    border-radius: ${props => props.radius ? props.radius : "20px"};
    box-shadow: ${props => props.shadow ? props.shadow : ""};
    line-height: 20px;
    gap: 8px;
    margin:0 0px;
    transition: all 0.6s ease-in-out;
    box-shadow: 3px 4px 8px 0px #47474796;
        
    span {
        padding: 0 !important;
        font-family: var(--semiBold) !important;
        transition: all .6s ease-in-out;
        background: var(--gradientDark);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        text-transform: uppercase;

        &:hover {
            background: var(--gradientLight);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
        }
    }
    svg{
        [stroke]{
            stroke: ${props => props.stroke ? props.stroke : "var(--themeColor)"} ;
        }
        [fill]{
            fill: ${props => props.fill ? props.fill : "var(--themeColor)"};
        }
    }
    &:hover{
        background:var(--gradientDark)!important;
        border:none !important;
        span {
            background: var(--gradientLight);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
        }
        svg{
            [stroke]{
                stroke: ${props => props.stroke ? props.stroke : "var(--pale)"} ;
            }
            [fill]{
                fill: ${props => props.fill ? props.fill : "var(--pale)"};
            }
        }
    }

  }
`

export const ButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background:${props => props.bg ? props.bg : "var(--gradientLight)"};
    color: ${props => props.color ? props.color : ""};
    padding: ${props => props.padding ? props.padding : "6px 16px"};
    border: ${props => props.border ? props.border : ""};
    border-radius: ${props => props.radius ? props.radius : "20px"};
    gap: 8px;
    transition: all 0.4s ease-in-out;
    
    &:hover{
        background:var(--gradientDark)!important;
        border: none !important;
        color: var(--white);
        span {
            background: var(--gradientLight);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
        }
        svg{
            [stroke]{
                stroke: var(--white);
            }
            [fill]{
                fill: var(--white);
            }
        }
    }
    
}
`
export const ModelButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    background:${props => props.bg ? props.bg : "var(--gradientDark)"};
    color: ${props => props.color ? props.color : ""};
    padding: ${props => props.padding ? props.padding : "6px 16px"};
    border: ${props => props.border ? props.border : "none"};
    border-radius: ${props => props.radius ? props.radius : "20px"};
    gap: 8px;
    transition: all 0.4s ease-in-out;
    span {
        background: var(--gradientLight);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
    }
    &:hover{
        background:var(--gradientLight)!important;
        border: none !important;
        color: var(--white);
        span {
            background: var(--gradientDark);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
        }
        svg{
            [stroke]{
                stroke: var(--white);
            }
            [fill]{
                fill: var(--white);
            }
        }
    }
    
}
`


export const TabButton = (props) => {
    const unwantedProps = ['label'];
    return (
        <TabButtonWrapper {...sliceProps(props, unwantedProps)}>
            <span {...sliceProps(props, unwantedProps)}>
                {props.label ? props.label : ''}
            </span>
        </TabButtonWrapper>
    )
}
export const TabButtonWrapper = styled.div`
    padding: 8px 20px;
    margin:15px 0;
    position: relative;
   
    cursor: pointer;
    span{
        display: flex;
        align-items: center;
        color: var(--text);
        font-size: 16px;
        font-family: var(--regular);
        text-align: center;
        white-space: nowrap;
        transition: all .4s ease-in-out;
        
    }
    &.active {
        background: var(--gradientDark);
        border-radius:20px;
        box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -webkit-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -moz-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
    }
    &.active span {
        background:  var(--gradientLight);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-family: var(--semibold);
    }
    &:hover{
        background: var(--gradientDark);
        border-radius:20px;
        box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -webkit-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
        -moz-box-shadow: 0px 0px 5px -5px rgba(172,131,34,0.9);
    }
    &:hover span {
        background:  var(--gradientLight);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-family: var(--semibold);
    }
   
    `